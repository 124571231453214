var enterPressed = false

function init() {
  const inputElements = document.querySelectorAll('.bu__dealer-attributes input[type="text"]');
  inputElements.forEach(element => addEventListenersToInput(element));
  const iconUploadElements = document.querySelectorAll('.bu__dealer-attributes input[type="file"]');
  iconUploadElements.forEach(element => addEventListenersToUpload(element));
}

function handleDealerAttributeAddition(event) {
  if (event.target && event.target.id === 'dealer-attributes__add') {
    const index = document.querySelectorAll('.dealer-attributes__row tr').length;
    const businessUnitId = event.target.dataset.businessUnitId;
    const indexData = `index=${index}`;
    const url = `/business_units/${businessUnitId}/dealer_attributes/new`;

    Rails.ajax({
      type: 'GET',
      url: url,
      data: indexData,
      success: (response, status, xhr) => {
        const dealerAttributesContainer = document.getElementsByClassName('dealer-attributes__row')[0];
        const rawResponse = xhr.response;
        dealerAttributesContainer.insertAdjacentHTML('beforeend', rawResponse);
        const addAttributeButton = document.getElementById('dealer-attributes__add');
        const inputElements = document.querySelectorAll('input[type="text"]');
        const inputElement = inputElements[inputElements.length - 1];
        addEventListenersToInput(inputElement);
        inputElement.focus();
        addAttributeButton.disabled = true;
      }
    });
  }
}

function submitForm() {
  const form = document.getElementsByTagName('FORM')[0];

  const icon_elements = document.querySelectorAll('.icon-upload');
  icon_elements.forEach(element => removeEmptyIcon(element));

  Rails.fire(form, 'submit');
}

function removeEmptyIcon(element) {
  if (!element.value) {
    element.remove();
  }
}

function handleDealerAttributeRemoval(event) {
  if (event.target && event.target.classList.contains('dealer-attributes__remove')) {
    const id = event.target.dataset.id;
    const hiddenFormElement = document.getElementById(`destroy-${id}`);
    hiddenFormElement.value = 1;
  }
}

function addEventListenersToInput(inputElement) {
  inputElement.addEventListener('blur', handleInputBlur);
  inputElement.addEventListener('focus', handleInputFocus);
}

function handleInputBlur(event) {
  const addAttributeButton = document.getElementById('dealer-attributes__add');
  const confirmAttributeButton = document.getElementById('dealer-attributes__dummy-confirm');
  addAttributeButton.style.display = 'inline';
  confirmAttributeButton.style.visibility = 'hidden';

  const iconUploadElements = document.querySelectorAll('.bu__dealer-attributes input[type="file"]');
  iconUploadElements.forEach(element => element.disabled = true);

  const inputElement = event.target;
  if (inputElement.value && inputElement.dataset.oldValue !== inputElement.value
      && !enterPressed) {
    submitForm();
  }
}

function handleInputFocus(event) {
  const addAttributeButton = document.getElementById('dealer-attributes__add');
  const confirmAttributeButton = document.getElementById('dealer-attributes__dummy-confirm');
  addAttributeButton.style.display = 'none';
  confirmAttributeButton.style.visibility = 'visible';
  event.target.dataset.oldValue = event.target.value;
}

function addEventListenersToUpload(uploadElement){
  uploadElement.addEventListener('change', submitForm);
}

function handleOnKeyUpEnter(event){
  if ( event.keyCode === 13 ) {
    enterPressed = true;
  }
  const icon_elements = document.querySelectorAll('.icon-upload');
  icon_elements.forEach(element => removeEmptyIcon(element));
}

export default {
  onClick(event) {
    handleDealerAttributeAddition(event);
    handleDealerAttributeRemoval(event);
  },
  onTurbolinksLoad() {
    init();
  },
  onKeyUp(event) {
    handleOnKeyUpEnter(event);
  }
}
