export function hideElement(element) {
  element.style.display = 'none';
}

export function disableLink(element) {
  element.dataset.href = element.href;
  element.href = '';
  element.classList.add('disabled');
  element.setAttribute('aria-disabled', 'true');
}

export function enableLink(element) {
  element.href = element.dataset.href;
  element.classList.remove('disabled');
  element.removeAttribute('data-href');
  element.removeAttribute('aria-disabled');
}

export function hideErrors() {
  const errorsElement = document.getElementById('errors');
  if (errorsElement) hideElement(errorsElement);
}

export function displayErrors(errors) {
  const errorsElement = document.getElementById('errors');

  if (errorsElement) {
    const oldErrors = errorsElement.firstChild;
    const formRow = document.createElement('DIV');
    formRow.classList.add('form__row', 'callout', 'alert', 'small');
    const list = document.createElement('UL');

    if (oldErrors) {
      errorsElement.replaceChild(formRow, oldErrors);
    } else {
      errorsElement.appendChild(formRow);
    }

    formRow.appendChild(list);
    errorsElement.style.display = 'flex';

    errors.forEach(error => {
      const node = document.createElement('LI');
      const textnode = document.createTextNode(error);
      node.appendChild(textnode);
      list.appendChild(node);
    });
  }
}
