import {displayErrors} from './helpers.js';

function handleUploadProgress() {
  const progressContainer = document.getElementById('upload__progress');
  if (progressContainer && progressContainer.dataset.processing === 'true') {
    const uuid = progressContainer.dataset.uuid;
    const showDealersButton = document.getElementsByClassName('show-dealers-button')[0];

    showDealersButton.style.display = 'none';

    // If we already have a subscription, unsubscribe the old one first
    if (DealerLocator.uploadProgress) {
      DealerLocator.cable.subscriptions.remove(DealerLocator.uploadProgress)
    }

    const settings = {
      channel: 'UploadProgressChannel',
      upload_id: uuid
    };

    DealerLocator.uploadProgress = DealerLocator.cable.subscriptions.create(settings, {
      received: data => {
        const progressContainer = document.getElementById('upload__progress');

        // This happens when the user is currently uploading a file in the background
        // and navigating to a different page => websocket connection is still open
        // and triggering 'received' events. We need to terminate it here.
        if (!progressContainer) {
          DealerLocator.cable.subscriptions.remove(DealerLocator.uploadProgress)
          return;
        }

        updateProgress(progressContainer, data);
      }
    });
  }
}

function updateProgress(progressContainer, data) {
  const progressElement = progressContainer.getElementsByTagName('progress')[0];
  const uploadStatusElement = document.getElementsByClassName('upload-status')[0];
  const progress = data['progress'];

  if (progress['errors']) {
    displayErrorsOnProgress(progress['errors']);
    progressElement.classList.add('alert');
  }

  if (progress['failed'] > 0) {
    incrementCounter('failed', progress);
    progressElement.classList.add('alert');
  }

  if (progress['created'] > 0) {
    incrementCounter('created', progress);
  }

  if (progress['updated'] > 0) {
    incrementCounter('updated', progress);
  }

  if (progress['unchanged'] > 0) {
    incrementCounter('unchanged', progress);
  }

  switch (progress.status) {
    case 'enqueued':
      uploadStatusElement.innerHTML = '<i class="fi-loop spin" style="color:green;"></i> Enqueued';
      break;
    case 'processing':
      uploadStatusElement.innerHTML = '<i class="fi-loop spin" style="color:green;"></i> Processing';
      progressContainer.style.display = 'flex';
      updateProgressBar(progressElement, progress);
      break;
    case 'publishing':
      uploadStatusElement.innerHTML = '<i class="fi-loop spin" style="color:green;"></i> Publishing';
      progressContainer.style.display = 'flex';
      updateProgressBar(progressElement, progress);
      break;
    case 'failed':
      uploadStatusElement.innerHTML = '<i class="fi-alert" style="color:red;"></i> Failed';
      const retryButton = document.getElementsByClassName('retry-button')[0];
      retryButton.style.visibility = 'visible';
      progressContainer.style.display = 'flex';
      progressElement.classList.add('alert');
      return;
    case 'success':
      uploadStatusElement.innerHTML = '<i class="fi-check" style="color:green;"></i> Success';
      progressContainer.style.display = 'flex';
      updateProgressBar(progressElement, progress);
      return Turbolinks.visit(window.location.toString());
    case 'preview':
      uploadStatusElement.innerHTML = '<i class="fi-check" style="color:green;"></i> Preview';
      progressContainer.style.display = 'flex';
      updateProgressBar(progressElement, progress);
      return Turbolinks.visit(window.location.toString());
  }
}

function updateProgressBar(progressElement, progress) {
  if (progress['count'] && progress['total']) {
    progressElement.value = Math.ceil(progress['count'] / progress['total'] * 100);
  }
}

function incrementCounter(name, progress) {
  const counter = document.getElementsByClassName(`${name}-value`)[0];
  counter.innerText = progress[name];
}

function handleUploadError(event) {
  if (event.target && event.target.id === 'new_upload') {
    const data = event.detail[0];
    displayErrors(data.errors);
  }
}

function handleFileFieldChange(event) {
  const fileFieldElementId = 'upload_file';
  const fileNameElement = document.getElementById('file_name');
  const importButtonElement = document.getElementById('import_button');

  if (event.target && event.target.id === fileFieldElementId) {
    fileNameElement.value = event.target.files[0].name;
    importButtonElement.removeAttribute('disabled');
  }
}

function handleBusinessUnitChange(event) {
  const businessUnitElement = document.getElementById('upload_business_unit_id');
  const marketElement = document.getElementById('upload_market_id');

  if (event.target && event.target === businessUnitElement) {
    const businessUnitId = businessUnitElement.options[businessUnitElement.selectedIndex].value;
    const marketId = marketElement.options[marketElement.selectedIndex].value
    updateBrands(marketId, businessUnitId);
  }
}

function handleMarketChange(event) {
  const marketElement = document.getElementById('upload_market_id');

  if (event.target && event.target === marketElement) {
    var marketId = marketElement.options[marketElement.selectedIndex].value;
    updateBusinessUnits(marketId);
  }
}

function updateBrands(marketId, businessUnitId) {
  const businessUnitElement = document.getElementById('upload_business_unit_id');
  const brandElement = document.getElementById('upload_brand_id');
  const url = businessUnitElement.dataset.url;

  brandElement.classList.add('disabled');

  Rails.ajax({
    url: `${url}?market_id=${marketId}&business_unit_id=${businessUnitId}`,
    type: 'GET',
    success: brands => updateSelections(brandElement, brands)
  });
}

function updateBusinessUnits(marketId) {
  const businessUnitElement = document.getElementById('upload_business_unit_id');
  const brandElement = document.getElementById('upload_brand_id');
  const marketElement = document.getElementById('upload_market_id');
  const url = marketElement.dataset.url;

  businessUnitElement.classList.add('disabled');

  Rails.ajax({
    url: `${url}?market_id=${marketId}`,
    type: 'GET',
    success: business_units => {
      updateSelections(businessUnitElement, business_units);
      updateSelections(brandElement, [])
    }
  });
}

function updateSelections(element, entries) {
  element.innerHTML = '';
  const option = document.createElement('option');
  option.text = '';
  option.selected = 'selected';
  element.add(option);

  entries.forEach(entry => {
    const option = document.createElement('option');
    option.text = entry.name;
    option.value = entry.id;
    element.add(option);
  });

  element.classList.remove('disabled');
}

function displayErrorsOnProgress(errors) {
  const errorsElement = document.getElementsByClassName('upload__errors')[0];

  if (errorsElement) {
    const divider = document.createElement('HR');
    const formRow = document.createElement('DIV')
    formRow.classList.add('form__row');
    const list = document.createElement('UL');

    errorsElement.appendChild(divider);
    errorsElement.appendChild(formRow);
    formRow.appendChild(list);

    errors.forEach(error => {
      const node = document.createElement('LI');
      const textnode = document.createTextNode(error);
      node.appendChild(textnode);
      list.appendChild(node);
    });
  }
}

function handlePreviewTableRows() {
  const normalRows = document.querySelectorAll('.uploads__table__row');
  const detailsRows = document.querySelectorAll('.uploads__table__details-row');

  normalRows.forEach(row => {
    row.addEventListener('click', () => {
      const matchedDetailsRows = document.querySelectorAll(`.uploads__table__details-row[data-id='${row.dataset.id}']`);
      matchedDetailsRows.forEach(row => {
        row.classList.remove('hidden');
      });
      row.classList.add('hidden');
    });
  });

  detailsRows.forEach(row => {
    row.addEventListener('click', () => {
      const matchedDetailsRows = document.querySelectorAll(`.uploads__table__details-row[data-id='${row.dataset.id}']`);
      const matchedNormalRow = document.querySelector(`.uploads__table__row[data-id='${row.dataset.id}']`);
      matchedDetailsRows.forEach(row => {
        row.classList.add('hidden');
      });
      matchedNormalRow.classList.remove('hidden');
    });
  });
}

function handleDeleteDealersCheckboxChange(event) {
  if (
    event.target && event.target.id == "upload_delete_existing_dealers"
  ) {

    if (event.target.checked == true) {
      const dialog = confirm(
        `Warning: Are you sure? All existing dealers not in your import file will be deleted for this business unit and brand.`
      );

      if (dialog == true) {
        event.target.value = 1;
      } else {
        event.target.value = 0;
        event.target.checked = false;
      }
    } else {
      event.target.value = 0;
    }
  }
}

export default {
  onTurbolinksLoad() {
    handleUploadProgress();
    handlePreviewTableRows();
  },
  onChange(event) {
    handleFileFieldChange(event);
    handleBusinessUnitChange(event);
    handleMarketChange(event);
    handleDeleteDealersCheckboxChange(event);
  },
  onAjaxError(event) {
    handleUploadError(event);
  }
}
