import Callouts from "./callouts";
import Markets from "./markets";
import Dealers from "./dealers";
import Dealer from "./dealer";
import Uploads from "./uploads";
import Exports from "./exports";
import BusinessUnits from "./business-units";
import { disableLink } from "./helpers";
import { createConsumer } from "@rails/actioncable"

class DealerLocator {
  constructor() {
    this.cable = createConsumer();
  }

  init() {
    Rails.start();
    Turbolinks.start();
    Foundation.addToJquery($);

    /**
     * Event delegation
     * See: https://github.com/turbolinks/turbolinks#installing-javascript-behavior
     */
    document.addEventListener("turbolinks:load", () => {
      this._onTurbolinksLoad();
      Dealer.onTurbolinksLoad();
      Dealers.onTurbolinksLoad();
      Uploads.onTurbolinksLoad();
      BusinessUnits.onTurbolinksLoad();
      Markets.onTurbolinksLoad();
    });

    document.addEventListener("click", event => {
      Callouts.onClick(event);
      Dealers.onClick(event);
      BusinessUnits.onClick(event);
      Exports.onClick(event);
    });

    document.addEventListener("change", event => {
      Markets.onChange(event);
      Dealers.onChange(event);
      Uploads.onChange(event);
      Exports.onChange(event);
    });

    document.addEventListener("ajax:error", event => {
      Uploads.onAjaxError(event);
      Exports.onAjaxError(event);
    });

    document.addEventListener("confirm:complete", event => {
      Dealers.onConfirmComplete(event);
    });

    document.addEventListener("keyup", event => {
      BusinessUnits.onKeyUp(event);
    });
  }

  _onTurbolinksLoad() {
    $(document).foundation();
    const linksToDisable = document.querySelectorAll("a.disabled");
    for (let i = 0; i < linksToDisable.length; i++) {
      disableLink(linksToDisable[i]);
    }
  }
}

export default DealerLocator;
