import { hideElement, displayErrors, hideErrors } from "./helpers";

function handleBusinessUnitChange(event) {
  const businessUnitElement = document.getElementById(
    "export_business_unit_id"
  );
  const marketElement = document.getElementById("export_market_id");

  if (event.target && event.target === businessUnitElement) {
    const businessUnitId =
      businessUnitElement.options[businessUnitElement.selectedIndex].value;
    const marketId = marketElement.options[marketElement.selectedIndex].value;
    updateBrands(marketId, businessUnitId);
  }
}

function handleMarketChange(event) {
  const marketElement = document.getElementById("export_market_id");

  if (event.target && event.target === marketElement) {
    var marketId = marketElement.options[marketElement.selectedIndex].value;
    updateBusinessUnits(marketId);
  }
}

function updateBrands(marketId, businessUnitId) {
  const businessUnitElement = document.getElementById(
    "export_business_unit_id"
  );
  const brandElement = document.getElementById("export_brand_id");
  const url = businessUnitElement.dataset.url;

  brandElement.classList.add("disabled");

  Rails.ajax({
    url: `${url}?market_id=${marketId}&business_unit_id=${businessUnitId}`,
    type: "GET",
    success: brands => updateSelections(brandElement, brands)
  });
}

function updateBusinessUnits(marketId) {
  const businessUnitElement = document.getElementById(
    "export_business_unit_id"
  );
  const brandElement = document.getElementById("export_brand_id");
  const marketElement = document.getElementById("export_market_id");
  const url = marketElement.dataset.url;

  businessUnitElement.classList.add("disabled");

  Rails.ajax({
    url: `${url}?market_id=${marketId}`,
    type: "GET",
    success: business_units => {
      updateSelections(businessUnitElement, business_units);
      updateSelections(brandElement, []);
    }
  });
}

function updateSelections(element, entries) {
  element.innerHTML = "";
  const option = document.createElement("option");
  option.text = "";
  option.selected = "selected";
  element.add(option);

  entries.forEach(entry => {
    const option = document.createElement("option");
    option.text = entry.name;
    option.value = entry.id;
    element.add(option);
  });

  element.classList.remove("disabled");
}

function subscribeToExportChannel(uuid) {
  DealerLocator.export = DealerLocator.cable.subscriptions.create(
    { channel: "ExportChannel", uuid: uuid },
    {
      connected: () => {
        if (!DealerLocator.initallyConnected) {
          DealerLocator.initallyConnected = true;
          submitForm();
        }
      },
      disconnected: () => resetForm(),
      received: data => {
        if (data.errors) {
          displayErrors(data.errors);
        } else {
          var blob = new Blob([str2bytes(window.atob(data.xlsx))], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          saveAs(blob, data.filename);
        }
        resetForm();
      }
    }
  );
}

function resetForm() {
  const exportDealersButton = document.getElementById("export_dealers_button");
  exportDealersButton.disabled = false;
  exportDealersButton.innerText = "Download";
  const blockableElements = document.getElementsByClassName("blockable");
  for (let i = 0; i < blockableElements.length; i++) {
    blockableElements[i].classList.remove("disabled");
  }
}

function handleExportRequested(event) {
  if (event.target && event.target.id === "export_dealers_button") {
    hideErrors();
    const blockableElements = document.getElementsByClassName("blockable");
    const exportDealersButton = document.getElementById(
      "export_dealers_button"
    );
    exportDealersButton.disabled = true;
    exportDealersButton.innerText = "Downloading ...";
    for (let i = 0; i < blockableElements.length; i++) {
      blockableElements[i].classList.add("disabled");
    }
    const uuidField = document.getElementById("uuid_field");
    var uuid = uuidField.value;
    if (!DealerLocator.export) {
      subscribeToExportChannel(uuid);
    } else {
      submitForm();
    }
  }
}

function submitForm() {
  const newExportForm = document.getElementById("new_export");
  Rails.fire(newExportForm, "submit");
}

function handleExportWithErrors(event) {
  if (event.target && event.target.id === "new_export") {
    const data = event.detail[0];
    displayErrors(data.errors);
    resetForm();
  }
}

function str2bytes(str) {
  var bytes = new Uint8Array(str.length);
  var i = 0;
  while (i < str.length) {
    bytes[i] = str.charCodeAt(i);
    i++;
  }
  return bytes;
}

export default {
  onClick(event) {
    handleExportRequested(event);
  },
  onChange(event) {
    handleBusinessUnitChange(event);
    handleMarketChange(event);
  },
  onAjaxError(event) {
    handleExportWithErrors(event);
  }
};
