function renderMap() {
  const mapContainer = document.querySelector(".dealer__map");

  if (!mapContainer) return;

  const $lat = document.getElementById("dealer_latitude");
  const $lng = document.getElementById("dealer_longitude");

  let dealerPosition = null;
  let center = new google.maps.LatLng(0, 0);
  let zoom = 2;

  const dealerLat = Number($lat.value);
  const dealerLng = Number($lng.value);

  if (dealerLat && dealerLng) {
    dealerPosition = new google.maps.LatLng(dealerLat, dealerLng);
    center = dealerPosition;
    zoom = 11;
  }

  const map = new google.maps.Map(mapContainer, {
    center,
    zoom,
    mapTypeId: "roadmap",
    scrollwheel: false,
    streetViewControl: false,
    fullscreenControl: false,
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }]
      }
    ]
  });

  const marker = new Marker(map, dealerPosition, $lat, $lng);
  initGeocoding(marker);
}

function setInitialAccuracy() {
  const $accuracyField = document.getElementById("dealer__geocoding-accuracy");
  const $geodataField = document.getElementById("dealer_geodata");
  const $geocodedByField = document.getElementById("dealer_geocoded_by");

  const $manuallyPositionedField = document.getElementById(
    "dealer__manually-positioned"
  );
  const $accuracyLevelField = document.getElementById(
    "dealer__geocoding-accuracy-level"
  );
  const $partialMatchField = document.getElementById("dealer_geocoding_partial_match");

  if (!$accuracyField && !$manuallyPositionedField && !$accuracyLevelField) {
    return;
  }

  if ($manuallyPositionedField.value === "true") {
    updateAccuracyLabel($accuracyField.value, "manually", $partialMatchField.value);
  } else {
    updateAccuracyLabel($accuracyField.value, $accuracyLevelField.value, $partialMatchField.value, $geocodedByField.value, JSON.parse($geodataField.value));
  }
}

function updateAccuracyLabel(accuracy, accuracyText, partial_match, geocoded_by, geodata) {
  const $accuracyContainer = document.querySelector(
    ".dealer__geocoding-accuracy"
  );
  const $formattedAddressContainer = document.querySelector(".dealer__formatted-address");

  const indicator = document.createElement("SPAN");
  const text = document.createTextNode("");
  const formatted_address_text = document.createTextNode("");
  const warnicon = document.createElement("IMG");
  warnicon.src = getWarningIconUrl();
  warnicon.height = 60;
  warnicon.width = 60;
  warnicon.alt = "Warning";

  indicator.classList.add("dealers__geocoding");

  switch (accuracyText) {
    case "manually":
      indicator.classList.add("dealers__geocoding--success");
      text.textContent = "Manually positioned";
      break;
    case "success":
      indicator.classList.add("dealers__geocoding--success");
      text.textContent = `${accuracy} / 9 Accuracy`;
      break;
    case "warning":
      indicator.classList.add("dealers__geocoding--warning");
      text.textContent = `${accuracy} / 9 Accuracy`;
      break;
    case "error":
      indicator.classList.add("dealers__geocoding--error");
      text.textContent = `${accuracy} / 9 Accuracy`;
      break;
    case "no_result":
      indicator.classList.add("dealers__geocoding--error");
      text.textContent = `No geocoding results`;
      break;
    default:
      indicator.classList.add("dealers__geocoding--unknown");
      text.textContent = "Unknown accuracy";
  }

  if (partial_match == "true" || partial_match == true){
    text.textContent += " - Partial match"
  }

  if (geodata && geodata["formatted_address"]) {
    const warniconSpan = document.createElement("SPAN");

    if (geocoded_by == 'google_places_search') {
      formatted_address_text.textContent = ` Address not reliably identified, but found dealer with name: '${geodata["name"]}'. `;
      warniconSpan.className = "dealer__warn-icon";
      warniconSpan.appendChild(warnicon);
    }
    formatted_address_text.textContent += `Adress location on map: `;
    formatted_address_text.textContent += `${geodata["formatted_address"]}`;
    $formattedAddressContainer.textContent = "";

    if (geocoded_by == 'google_places_search') {
      $formattedAddressContainer.appendChild(warniconSpan);
    }
    const textSpan = document.createElement("SPAN");
    const textDiv = document.createElement("DIV");

    textDiv.appendChild(formatted_address_text);
    textSpan.appendChild(textDiv);

    $formattedAddressContainer.appendChild(textSpan);
  } else {
    $formattedAddressContainer.textContent = '';
  }

  $accuracyContainer.textContent = "";
  $accuracyContainer.appendChild(indicator);
  $accuracyContainer.appendChild(text);
}

class Marker {
  constructor(map, position, $lat, $lng) {
    this.map = map;
    this.$lat = $lat;
    this.$lng = $lng;
    this.positionToResetTo = position;
    this.$manuallyPositioned = document.getElementById(
      "dealer__manually-positioned"
    );
    this.$changePosition = document.querySelector(
      ".dealer__map__change-marker-position"
    );
    this.$resetPosition = document.querySelector(
      ".dealer__map__reset-marker-position"
    );
    this.enableManualPositioning = this.enableManualPositioning.bind(this);
    this.startManualPositioning = this.startManualPositioning.bind(this);
    this.updateManualPosition = this.updateManualPosition.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.resetPosition = this.resetPosition.bind(this);

    this.init(position);
  }

  init(position) {
    this.marker = new google.maps.Marker({
      position: position || new google.maps.LatLng(0, 0),
      icon: getIcon(),
      visible: Boolean(position)
    });
    this.marker.setMap(this.map);

    this.marker.addListener("dragstart", this.startManualPositioning);
    this.marker.addListener("dragend", this.updateManualPosition);
    this.$changePosition.addEventListener(
      "click",
      this.enableManualPositioning
    );
    this.$resetPosition.addEventListener("click", this.resetPosition);

    this.$changePosition.classList.remove("dealer__map__button--hidden");
  }

  enableManualPositioning() {
    this.marker.setDraggable(true);
    this.marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(() => this.marker.setAnimation(null), 1000);
    this.$changePosition.classList.add("dealer__map__button--hidden");
  }

  resetPosition() {
    const $accuracyField = document.getElementById(
      "dealer__geocoding-accuracy"
    );
    const $accuracyLevelField = document.getElementById(
      "dealer__geocoding-accuracy-level"
    );
    const $partialMatchField = document.getElementById("dealer_geocoding_partial_match");
    const position = this.positionToResetTo;
    this.marker.setDraggable(false);
    this.marker.setPosition(position);
    this.marker.setVisible(Boolean(position));
    this.map.setZoom(14);
    this.map.setCenter(position);
    this.$lat.value = position.lat();
    this.$lng.value = position.lng();
    this.$manuallyPositioned.value = false;
    this.$resetPosition.classList.add("dealer__map__button--hidden");
    this.$changePosition.classList.remove("dealer__map__button--hidden");

    const $geocodedBy = document.getElementById("dealer_geocoded_by");
    const $geodata = document.getElementById("dealer_geodata");

    updateAccuracyLabel($accuracyField.value, $accuracyLevelField.value, $partialMatchField.value, $geocodedBy.value, JSON.parse($geodata.value));
  }

  startManualPositioning() {
    this.marker.setAnimation(null);
  }

  updateManualPosition() {
    const position = this.marker.getPosition().toJSON();
    this.$lat.value = position.lat;
    this.$lng.value = position.lng;
    this.$manuallyPositioned.value = true;
    this.$resetPosition.classList.remove("dealer__map__button--hidden");
    updateAccuracyLabel(null, "manually", false);
  }

  updatePosition(position) {
    this.positionToResetTo = position;
    this.resetPosition();
  }
}

function initGeocoding(marker) {
  const $form = document.querySelector(".form--dealer");
  const addressElements = {
    name: document.getElementById("dealer_name"),
    street: document.getElementById("dealer_address_street"),
    zip: document.getElementById("dealer_address_zip_code"),
    city: document.getElementById("dealer_address_city"),
    state: document.getElementById("dealer_address_state"),
    country: document.getElementById("dealer_address_country")
  };
  const $skipGeocoding = document.getElementById("dealer_skip_geocoding");
  const $accuracy = document.getElementById("dealer__geocoding-accuracy");
  const $accuracyLevel = document.getElementById(
    "dealer__geocoding-accuracy-level"
  );

  const $placeId = document.getElementById("dealer_geocoding_place_id");
  const $geodata = document.getElementById("dealer_geodata");
  const $administrative_area_1 = document.getElementById(
    "dealer_administrative_area_1"
  );
  const $administrative_area_2 = document.getElementById(
    "dealer_administrative_area_2"
  );

  const $geocodedAt = document.getElementById("dealer_geocoded_at");
  const $geocodedBy = document.getElementById("dealer_geocoded_by");
  const $placeTypes = document.getElementById("dealer_place_types");
  const $partialMatch = document.getElementById("dealer_geocoding_partial_match");
  const $geocoder = $form.dataset.geocoder;
  const $market_name = document.getElementById("name");

  let preventedSubmit = false;

  const getAddress = () =>
    Object.keys(addressElements)
      .map(key => addressElements[key].value)
      .join("**-**");

  let geocodedAddress = getAddress();
  let sameLocationCheckRunning = false;
  let dealersAtSameLocation = [];

  const getDealersAtSameLocation = () => {
    var FD = new FormData($form);
    sameLocationCheckRunning = true;
    fetch(`${$form.action}/at_same_location.json`, {
      method: "POST",
      body: FD
    })
      .then(response => response.json())
      .then(response => {
        sameLocationCheckRunning = false;
        dealersAtSameLocation = response;
      })
      .catch(err => {
        sameLocationCheckRunning = false;
      });
  };

  const geocode = () => {
    const address = getAddress();

    return fetch(`/geolocation.json?q=${encodeURIComponent(address)}&lookup=${$geocoder}&market_name=${$market_name.value}`)
      .then(response => response.json())
      .then(response => {
        geocodedAddress = address;
        const { latitude, longitude } = response;
        const position = new google.maps.LatLng(latitude, longitude);

        marker.updatePosition(position);

        $accuracy.value = response.geocoding_accuracy;
        $accuracyLevel.value = response.geocoding_accuracy_level;
        $placeId.value = response.geocoding_place_id;
        $administrative_area_1.value = response.administrative_area_1;
        $administrative_area_2.value = response.administrative_area_2;
        $skipGeocoding.value = true;
        $geodata.value = JSON.stringify(response.geodata);
        $geocodedAt.value = response.geocoded_at;
        if (response.geocoded_by){
          $geocodedBy.value = response.geocoded_by;
        }
        $partialMatch.value = response.geocoding_partial_match;
        $placeTypes.value = response.place_types;

        updateAccuracyLabel(
          response.geocoding_accuracy,
          response.geocoding_accuracy_level,
          response.geocoding_partial_match,
          response.geocoded_by,
          response.geodata
        );

        if (preventedSubmit) {
          $form.submit();
        }
      })
      .catch(() => {
        updateAccuracyLabel(null, "no_result", false);
        // e.g. "Geocoding results are empty"
        $partialMatch.value = null;
        $accuracy.value = 0;
        $accuracyLevel.value = 0;
        $placeId.value = null;
        $administrative_area_1.value = null;
        $administrative_area_2.value = null;
        $skipGeocoding.value = true;
        $geodata.value = null;

        if (preventedSubmit) {
          $form.submit();
        }
      });
  };

  Object.keys(addressElements).forEach(key => {
    if ($geocoder === "bing" && key === "name") {
      return;
    }
    const element = addressElements[key];
    element.addEventListener("change", geocode);
    element.addEventListener("change", getDealersAtSameLocation);
  });

  $form.addEventListener("submit", event => {
    if (!sameLocationCheckRunning && dealersAtSameLocation.length > 0) {
      const result = confirm(
        `There are ${dealersAtSameLocation.length} dealers at the same location`
      );
      if (!result) {
        event.preventDefault();
        setTimeout(() => Rails.enableElement($form.elements.commit), 500);
      }
    }

    const geocodingReady = geocodedAddress === getAddress();
    if (!geocodingReady || sameLocationCheckRunning) {
      preventedSubmit = true;
      event.preventDefault();
    }
  });
}

function getIcon() {
  const size = { width: 30, height: 37 };
  const colors = { outer: "#000000", inner: "#FFA500" };
  const svgUrl = getSvgUrl(size, colors);

  return {
    url: svgUrl,
    size: new google.maps.Size(size.width, size.height),
    anchor: new google.maps.Point(size.width / 2, size.height)
  };
}

function getSvgUrl(size, colors) {
  const svg = `<svg width="${size.width}" height="${size.height}" viewBox="0 0 24 30"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M1.2 13.6a9 9 0 1 1 15.5 0c-.4.6-1 1.8-4.1 4.4-3.3 2.7-2.4 6.4-3.6 6.4-1.2 0-.3-3.7-3.6-6.4-3.2-2.6-3.7-3.8-4.2-4.4z" id="b"/>
      <filter x="-27.8%" y="-16.4%" width="155.6%" height="141%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" in="shadowBlurOuter1"/>
      </filter>
    </defs>
    <g transform="translate(3 2)" fill="none" fill-rule="evenodd">
      <use fill="black" filter="url(#a)" xlink:href="#b"/>
      <use fill="${colors.outer}" xlink:href="#b"/>
      <circle fill="${colors.inner}" cx="9" cy="9" r="3.5"/>
    </g>
  </svg>`;

  const encodedSvg = encodeURIComponent(svg);
  return `data:image/svg+xml;utf-8,${encodedSvg}`;
}

function getWarningIconUrl() {
  const svg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 21L12 2L1 21H23ZM11 18V16H13V18H11ZM11 14H13V10H11V14Z" fill="#FFA500"/>
    </svg>`;

  const encodedSvg = encodeURIComponent(svg);
  return `data:image/svg+xml;utf-8,${encodedSvg}`;
}

export default {
  onTurbolinksLoad() {
    renderMap();
    setInitialAccuracy();
  }
};
