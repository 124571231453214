function handleClose(event) {
  if (event.target && event.target.classList.contains('callout__close')) {
    const callout = event.target.parentElement;

    if (callout.hasAttribute('data-closeable')) {
      callout.classList.add('callout--hidden');
    }
  }
}

export default {
  onClick(event) {
    handleClose(event);
  },
};
