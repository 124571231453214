import { hideElement, disableLink } from './helpers';

function handleBusinessUnitTabs() {
  const businessUnitTabs = document.getElementsByClassName(
    'dealers__search__bu-tab'
  );
  const hiddenInputField = document.getElementById('dealers__search__bu-id');

  for (let i = 0; i < businessUnitTabs.length; i++) {
    const tab = businessUnitTabs[i];
    tab.addEventListener('click', event => {
      hiddenInputField.value = event.target.dataset.businessUnitId;
      submitForm();
    });
  }
}

function handleDealersDeleteButton(event) {
  if (event.target && event.target.id.includes('dealers__delete-button')) {
    // Do not continue if user rejected confirm dialog
    if (event.detail[0] === false) return;

    const checkboxes = document.getElementsByClassName('dealers__selectable');
    const bu = event.target.dataset.bu;
    let dealerIdsToDelete = [];

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      if (checkbox.dataset.bu === bu && checkbox.checked) {
        dealerIdsToDelete.push(checkbox.value);
      }
    }

    const params = encodeURI('dealer_ids[]=') + dealerIdsToDelete.join(encodeURI('&dealer_ids[]='));
    const url = `${event.target.dataset.href}&${params}`;

    Rails.ajax({
      url: url,
      type: 'DELETE'
    });
  }
}

function submitForm() {
  const formElement = document.getElementById('dealers__search__form');

  const elementsToBlock = document.querySelectorAll('[data-block="true"]');
  for (let i = 0; i < elementsToBlock.length; i++) {
    const element = elementsToBlock[i];
    element.style['pointer-events'] = 'none';
    element.classList.add('disabled');
  }

  const elementsToHide = document.querySelectorAll('[data-hide="true"]');
  for (let i = 0; i < elementsToHide.length; i++) {
    hideElement(elementsToHide[i]);
  }

  formElement.submit();
}

function handleCheckAllCheckbox(event) {
  if (event.target && event.target.id.includes('dealers__check-all')) {
    const bu = event.target.dataset.bu;
    const checkboxes = document.getElementsByClassName('dealers__selectable');
    const dealersDeleteButtonElement = document.getElementById(
      `dealers__delete-button-${bu}`
    );

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      if (checkbox.dataset.bu === bu) {
        if (event.target.checked) {
          checkbox.checked = true;
          toggleButton(dealersDeleteButtonElement, true);
        } else {
          checkbox.checked = false;
          toggleButton(dealersDeleteButtonElement, false);
        }
      }
    }
  }
}

function handleBrandCheckboxes(event) {
  if (
    event.target &&
    event.target.classList.contains('dealers__search__brand-checkbox')
  ) {
    const withCampaignsElement = document.getElementById('search_only_dealers_with_campaigns');
    if (withCampaignsElement.checked) {
      handleDealersWithCampaignsSearch(withCampaignsElement);
    } else {
      submitForm();
    }
  }
}

function handleMarketSelect(event) {
  if (event.target && event.target.id === 'dealers__search__markets-select') {
    submitForm();
  }
}

function handleTextSearch(event) {
  if (event.target && event.target.id === 'search_search_text') {
    submitForm();
  }
}

function handleInaccurateGeocodingSearch(event) {
  if (event.target && event.target.id === 'search_only_inaccurately_geocoded') {
    document.getElementById("search_only_places_geocoded").checked = false;
    submitForm();
  }
}

function handleDealersWithCampaignsSearchChange(event) {
  if (event.target && event.target.id === 'search_only_dealers_with_campaigns') {
    if (event.target.checked) {
      handleDealersWithCampaignsSearch(event.target)
    } else {
      submitForm();
    }
  }
}

function handleDealersWithCampaignsSearch(element) {
  if (element.checked) {
    if (document.querySelectorAll('.dealers__search__brand-checkbox:checked').length == 1) {
      submitForm();
    } else {
      document.getElementById("search_only_dealers_with_campaigns").checked = false;
      $('#featured_for_contracts_only').foundation('open');
    }
  }
}

function handlePlacesGeocodingSearch(event) {
  if (event.target && event.target.id === 'search_only_places_geocoded') {
    document.getElementById("search_only_inaccurately_geocoded").checked = false;
    submitForm();
  }
}

function handleDealerCheckboxes(event) {
  if (event.target && event.target.classList.contains('dealers__selectable')) {
    const bu = event.target.dataset.bu;
    const dealersDeleteButtonElement = document.getElementById(
      `dealers__delete-button-${bu}`
    );
    const checkboxes = document.getElementsByClassName('dealers__selectable');
    let numChecked = 0;

    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      if (checkbox.checked) {
        numChecked++;
      }
    }

    toggleButton(dealersDeleteButtonElement, numChecked > 0);
  }
}

function toggleButton(elem, enabled) {
  if (enabled) {
    elem.disabled = false;
    elem.classList.remove('disabled');
  } else {
    elem.disabled = true;
    elem.classList.add('disabled');
  }
}

function handleContractCheckboxChange(event) {
  if (
    event.target &&
    event.target.classList.contains("dealers__contract-checkbox")
  ) {
    const featuredElement = document.getElementById(event.target.dataset.featuredelementid);

    if (featuredElement){
      if (event.target.checked) {
        featuredElement.disabled = false;
        featuredElement.classList.remove('disabled');
      } else {
        featuredElement.disabled = true;
        featuredElement.classList.add('disabled');
        featuredElement.checked = false;
      }
    }
  }
}

export default {
  onTurbolinksLoad() {
    handleBusinessUnitTabs();
  },
  onClick(event) {
    handleCheckAllCheckbox(event);
    handleBrandCheckboxes(event);
  },
  onChange(event) {
    handleMarketSelect(event);
    handleDealerCheckboxes(event);
    handleTextSearch(event);
    handleInaccurateGeocodingSearch(event);
    handlePlacesGeocodingSearch(event);
    handleDealersWithCampaignsSearchChange(event);
    handleContractCheckboxChange(event);
  },
  onConfirmComplete(event) {
    handleDealersDeleteButton(event);
  }
};
