let geocoderService = "";
let form = null;
let saveButtonElement = null;
let confirmMessage = "";

function init() {
  const geocoderServiceElement = document.getElementById(
    "market_geocoder_service"
  );
  form = document.getElementById("markets_form");
  if (!form) return;

  confirmMessage = form.dataset.alert;
  saveButtonElement = document.getElementById("markets_save");
  geocoderService = geocoderServiceElement.value;
}

function handleGeocoderServiceChange(element) {
  if (element.value === geocoderService) {
    saveButtonElement.removeAttribute("data-confirm");
  } else {
    saveButtonElement.setAttribute("data-confirm", confirmMessage);
  }
}

function handleAttributeCheckboxChange(
  element,
  checkedCondition,
  targetId,
  targetCheckedValue
) {
  if (element.checked === checkedCondition) {
    const checkboxToChange = document.getElementById(targetId);
    checkboxToChange.checked = targetCheckedValue;
  }
}

function handleContractCheckboxChange(event) {
  if (
    event.target &&
    event.target.classList.contains("markets__contract-checkbox")
  ) {
    const label = event.target.parentNode.querySelector(
      `label[for="${event.target.id}"]`
    );
    const badge = label.querySelector(".badge");
    const dealerCount = badge.innerText;
    if (dealerCount > 0 && event.target.checked == false) {
      const dialog = confirm(
        `Warning: Are you sure you want to do this? ${dealerCount} dealers are affected by this change!`
      );

      if (dialog == true) {
        handleCampaignCheckboxesForContract(event.target);
        return true;
      } else {
        event.target.checked = true;
      }
    }
    handleCampaignCheckboxesForContract(event.target);
  }
}

function handleCampaignCheckboxesForContract(contractCheckboxElement) {
  const featuredDealerActiveId = contractCheckboxElement.dataset.featuredDealerActiveId;
  let campaignActiveElement = document.getElementById(
    featuredDealerActiveId
  );

  const featuredDealerFilterableId = campaignActiveElement.dataset.featuredDealerFilterableId;
  const campaignFilterableElement = document.getElementById(
    featuredDealerFilterableId
  );

  if (contractCheckboxElement.checked) {
    enableCheckbox(campaignActiveElement);
    if (campaignActiveElement.checked) {
      enableCheckbox(campaignFilterableElement);
    }

  } else {
    disableCheckbox(campaignActiveElement);
    campaignActiveElement.checked = false;
    disableCheckbox(campaignFilterableElement);
    campaignFilterableElement.checked = false;
  }
}

function handleCampaignsEnableCheckboxChange(event) {
  if (
    event.target &&
    event.target.classList.contains("markets__campaign-checkbox")
  ) {

    const businessUnitId = event.target.dataset.businessUnitId;
    const className = "contracts-" + businessUnitId;
    const contractElements = document.getElementsByClassName(className);

    for (const contractElement of contractElements) {
      if (event.target.checked) {
        showCampaignCheckboxes(contractElement);
      } else {
        hideCampaignCheckboxes(contractElement);
      }
    }
  }
}

function showCampaignCheckboxes(contractElement) {
  const featuredDealerActiveId = contractElement.dataset.featuredDealerActiveId;

  let activeCheckboxElement = document.getElementById(
    featuredDealerActiveId
  );
  showCheckbox(activeCheckboxElement);
  if (contractElement.checked) {
    enableCheckbox(activeCheckboxElement);
  }

  const featuredDealerFilterableId = activeCheckboxElement.dataset.featuredDealerFilterableId;
  const campaignFilterableElement = document.getElementById(
    featuredDealerFilterableId
  );
  showCheckbox(campaignFilterableElement);

  if (activeCheckboxElement.checked) {
    enableCheckbox(campaignFilterableElement);
  }
}

function hideCampaignCheckboxes(contractElement) {
  const featuredDealerActiveId = contractElement.dataset.featuredDealerActiveId;

  let campaignActiveElement = document.getElementById(
    featuredDealerActiveId
  );

  hideCheckbox(campaignActiveElement);

  const featuredDealerFilterableId = campaignActiveElement.dataset.featuredDealerFilterableId;
  const campaignFilterableElement = document.getElementById(
    featuredDealerFilterableId
  );
  hideCheckbox(campaignFilterableElement);
}

function showCheckbox(checkboxElement) {
  checkboxElement.labels[0].style.display = 'flex';
  const inactiveText = document.getElementById("disabledText" + checkboxElement.id);
  if (inactiveText){
    inactiveText.remove();
  }
}

function hideCheckbox(checkboxElement) {
  checkboxElement.labels[0].style.display = 'none';

  let s = document.createElement("SPAN");
  s.setAttribute("id", "disabledText" + checkboxElement.id);
  s.classList.add('disabled-note');
  const t = new Text('inactive');
  s.appendChild(t);

  checkboxElement.parentElement.append(s);
}

function handleCampaignActiveCheckboxChange(event) {
  if (
    event.target &&
    event.target.classList.contains("markets__campaign-active-checkbox")
  ) {

    const activeCheckboxElement = event.target;
    const featuredDealerFilterableId = activeCheckboxElement.dataset.featuredDealerFilterableId;

    const campaignFilterableElement = document.getElementById(
      featuredDealerFilterableId
    );

    if (activeCheckboxElement.checked) {
      enableCheckbox(campaignFilterableElement);
    } else {
      disableCheckbox(campaignFilterableElement);
      campaignFilterableElement.checked = false;
    }
  }
}

function disableCheckbox(checkboxElement) {
  checkboxElement.classList.add('disabled');
  checkboxElement.disabled = true;
}

function enableCheckbox(checkboxElement) {
  checkboxElement.disabled = false;
  checkboxElement.classList.remove('disabled');
}

export default {
  onTurbolinksLoad() {
    init();
  },
  onChange(event) {
    handleContractCheckboxChange(event);
    handleCampaignActiveCheckboxChange(event);
    handleCampaignsEnableCheckboxChange(event);

    const activeAttributeCheckboxClass = "dealer_attribute_active_checkbox";
    const filterableAttributeCheckboxClass =
      "dealer_attribute_filterable_checkbox";

    if (
      event.target &&
      event.target.classList.contains(activeAttributeCheckboxClass)
    ) {
      const id = filterableAttributeCheckboxClass + "_" + event.target.value;
      return handleAttributeCheckboxChange(event.target, false, id, false);
    }
    if (
      event.target &&
      event.target.classList.contains(filterableAttributeCheckboxClass)
    ) {
      const id =
        activeAttributeCheckboxClass + "_" + event.target.id.split("_").pop();
      return handleAttributeCheckboxChange(event.target, true, id, true);
    }

    if (event.target && event.target.id === "market_geocoder_service") {
      handleGeocoderServiceChange(event.target);
    }
  }
};
